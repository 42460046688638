<template>
  <div>
    <el-row type="flex" align="middle">
      <el-col :span="12">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
          <el-breadcrumb-item to="/cash-out-list">申请提现</el-breadcrumb-item>
          <el-breadcrumb-item>提现记录</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-link type="primary" @click="$router.back()">&lt;返回</el-link>
      </el-col>
    </el-row>

    <el-divider></el-divider>
    <bill-table cashing-record filter></bill-table>
  </div>
</template>

<script>
/**
 * 提现记录
 */
import BillTable from "@/views/income/components/BillTable"

export default {
  name: "CashingRecords",
  components:{
    BillTable
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
  .el-divider{
    background-color: #f5f5f5;
  }
</style>
